<template>
    <div :class="toolbarClass">
        <slot name="toolbar" :search="search">
            <div class="col">
                <SearchInput class="form-control-sm" @onSearch="search" />
            </div>
            <div v-if="$slots.toolbarActions" class="col-auto">
                <slot name="toolbarActions"></slot>
            </div>
        </slot>
    </div>
  
    <Tree :options ="options" v-slot={item} @nodeClick = "nodeClick" ref="treeContainer" class="p-2">
        <span :class="{ 'bg-primary text-white p-1': highlightActiveNode && currentlySelectedNode?.item?.ID === item.item.ID }">{{item.item.Name}} - {{item.item.Title}}</span>
    </Tree>
</template>




<script setup>
    import Tree from 'o365.vue.components.Tree.vue';
    import {ref,onMounted,watch} from 'vue';
   
    //import OrgunitsTreeControl from 'o365.modules.OrgUnitsTree.ts';
    import {getControl} from 'o365.modules.OrgUnitsTree.ts';
    import SearchInput from 'o365.vue.components.SearchInput.vue';
    const emit = defineEmits(['nodeClick']);

    const props = defineProps({
        whereClause: {
            type: String            
        },
        toolbarClass: {
            default: 'p-2 bg-light-subtle border-bottom row',
        },
        config: {
            type: Object,
            default() {
                return {
                    viewName:"sviw_System_OrgUnitsTreeForSelectorToolbar",
                    title:(item) => 'ID: '+item.ID + ', '+item.Name+ ' '+ item.Title,
                    parentField:"Parent_ID",
                    keyField:"ID",
                    keyPathField: "IdPath",
                    nodesCountField:"HasSubNodes",
                    fields:[                        
                        {name: "ID", type: "number"},
                        {name: "IdPath", type: "string" },
                        {name: "OrgUnit", type: "string"},
                        {name: "Closed", type: "date"},
                        {name: "Name", type: "string", sortOrder:1,sortDirection:"asc"},
                        {name: "Title", type: "string"},
                        {name: "Domain_ID", type: "number"},
                        {name: "Level", type: "number"},
                        {name: "UnitType", type: "string"},
                        {name: "NamePath", type: "string"},
                        {name: "Parent", type: "string"},
                        {name: "Parent_ID",type:"number"},
                        {name: "AccessIdPath", type: "string"}
                    ],
                }
            }
        },
        highlightActiveNode: {
            type: Boolean,
            default: false
        }
    });
   
    const treeContainer = ref(null);
   
    let treeControl = null;
    function search(pValue){
      //  treeControl.treeObject.resetNode();
        treeControl.treeObject.loading = true;
        treeControl.doSearch(pValue).then(()=>{
            treeControl.treeObject.loading = false;
        });
    }

    watch(props,()=>{
        treeContainer.value.treeObject.reloadWhereClause(props.whereClause);
    })
 

    const options = {
        id:"o_tree_orgunit",
        whereClause:props.whereClause,
       // initFilterString:"[Parent_ID] IS NULL",
        configs: [props.config]
    }

    onMounted(()=>{
        treeControl = getControl(treeContainer.value.treeObject);
    })

    const currentlySelectedNode = ref(null);

    const nodeClick = (node)=>{
        currentlySelectedNode.value = node;
        emit('nodeClick',node);
    }

    defineExpose({search, getTreeObject: () => treeContainer.value.treeObject } );
</script>
<style scoped>

</style>